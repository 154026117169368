import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PopupWrapper = styled.div`
  max-width: 560px;
  padding: 24px;
  background-color: #fff;
`;

export const Title = styled.h2`
  font-size: 20px;
`;

export const Description = styled.p`
  margin: 16px 0 20px;
`;

export const ButtonWrapper = styled.div`
  button {
    width: 100%;
    padding: 14px 10px;
    border-radius: 4px;
      font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: top;

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  
  button {
    width: calc(50% - 8px);
    padding: 14px 10px;
    border-radius: 4px;
      font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
    vertical-align: top;

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
`;

export const CancelButton = styled.button`
  background-color: #fff;
  border: solid 1px #D4D4D4;
  color: #EE504F;
  margin-right: 16px;
`;

export const ConfirmButton = styled.button`
  padding: 15px 10px !important;
  border: none;
  background-color: #EE504F;
  color: #fff;
`;

export const ModalClose = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  padding: 10px 15px;
  z-index: 1;

  cursor: pointer;
  display: block;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  border: 0;
  outline: none;
  display: inline-block;
  svg {
    width: 12px;
    height: 12px;
    color: #EE504F;
  }
  @media (max-width: 767px) {
    top: 5px;
    right: 0;
  }
`;

import React from 'react';
import { openModal, closeModal } from '@redq/reuse-modal';
import {
  PopupWrapper,
  Title,
  Description,
  ButtonWrapper,
  CancelButton,
  ConfirmButton,
  ModalClose,
} from './LegacyPopup.style';
import { CloseIcon } from 'components/AllSvgIcon';

type OKPopupProps = {
  title: string;
  description: string;
  okText?: string;
  cancelText?: string;
  didClickOk?: Function
  didClickCancel?: Function;
  didClickClose?: Function;
};

const OKPopup: React.FC<OKPopupProps> = ({
  title,
  description,
  okText,
  cancelText,
  didClickOk,
  didClickCancel,
  didClickClose,
}) => {

  const onClose = () => {
    if (didClickClose) didClickClose();
    closeModal();
  };

  const onCancel = () => {
    if (didClickCancel) didClickCancel();
    closeModal();
  };

  const onConfirm = () => {
    if (didClickOk) didClickOk();
    closeModal();
  };

  return (
    <>
      <ModalClose onClick={onClose}>
        <CloseIcon />
      </ModalClose>
      <PopupWrapper>
        <Title>{ title }</Title>
        <Description>{ description }</Description>

        { okText &&
            <ButtonWrapper>
                <ConfirmButton onClick={onConfirm}>{ okText }</ConfirmButton>
            </ButtonWrapper>
        }

        { cancelText &&
            <ButtonWrapper>
                <CancelButton onClick={onCancel}>{ cancelText }</CancelButton>
            </ButtonWrapper>
        }
      </PopupWrapper>
    </>
  );
};

export const showOkPopup = (props: OKPopupProps) => {
  setTimeout(() => {
    openModal({
      show: true,
      closeOnClickOutside: true,
      component: OKPopup,
      componentProps: props,
      closeComponent: 'div',
      config: {
        className: '',
        enableResizing: false,
        disableDragging: true,
        width: 560,
        height: 'auto',
        transition: {
          mass: 1,
          tension: 0,
          friction: 0,
        },
      },
    });
  }, 20);
};

export default showOkPopup;
